import React from 'react';
import {PlaceOrderDataHook} from '../PlaceOrderStep';
import {
  BottomMobileSummary,
  BottomMobileSummaryDataHooks,
} from '../../../../Summary/BottomMobileSummary/BottomMobileSummary';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../constants';

export const PlaceOrderEmpty = () => {
  const {isOneColumnView} = useControllerProps();
  const {experiments} = useExperiments();
  const shouldSupportNewMobileSummaryBehavior =
    experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) && isOneColumnView;
  return (
    <div data-hook={PlaceOrderDataHook.empty}>
      {shouldSupportNewMobileSummaryBehavior && (
        <BottomMobileSummary dataHook={BottomMobileSummaryDataHooks.placeOrderEmpty} />
      )}
    </div>
  );
};
