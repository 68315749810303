import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './SecureCheckout.st.css';
import Lock from './Lock.svg';
import {Text} from 'wix-ui-tpa/cssVars';
import {SPECS} from '../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum SecureCheckoutDataHook {
  lock = 'SecureCheckout.lock',
  text = 'SecureCheckout.text',
}
export const SecureCheckout = () => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const shouldSupportNewMobileSummaryBehavior = experiments.enabled(SPECS.SupportNewMobileSummaryBehavior);
  return (
    <div className={shouldSupportNewMobileSummaryBehavior ? classes.secureCheckout : classes.secureCheckoutOld}>
      <Lock
        className={classes.secureCheckoutLock}
        data-hook={SecureCheckoutDataHook.lock}
        aria-label={localeKeys.checkout.orderSummary.secureCheckout.label()}
      />
      <Text className={classes.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
        {localeKeys.checkout.orderSummary.secureCheckout.label()}
      </Text>
    </div>
  );
};
