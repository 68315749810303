import {Step} from '../../StepsManager/Components/Step';
import React from 'react';
import {PlaceOrderOpen} from './Components/PlaceOrderOpen';
import {PlaceOrderEmpty} from './Components/PlaceOrderEmpty';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps} from '../../../../../types/app.types';
import {SPECS} from '../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {classes as commonClasses} from '../../../common.st.css';
import {useControllerProps} from '../../../Widget/ControllerContext';

export enum PlaceOrderDataHook {
  root = 'PlaceOrder.root',
  subtitle = 'PlaceOrder.subtitle',
  collapsed = 'PlaceOrder.collapsed',
  open = 'PlaceOrder.open',
  empty = 'PlaceOrder.empty',
  placeOrderButton = 'PlaceOrder.placeOrderButton',
  violations = 'PlaceOrder.violations',
}

export const PlaceOrderStep = ({index}: StepImplementationProps) => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isDonationsTheme},
  } = useControllerProps();
  return (
    <Step
      index={index!}
      title={
        isDonationsTheme
          ? localeKeys.checkout.donations.review_donation.title()
          : localeKeys.checkout.place_order.$value()
      }
      dataHook={PlaceOrderDataHook.root}
      classNameStepHeader={
        experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? commonClasses.oneColumnViewPadding : undefined
      }
      openComponent={PlaceOrderOpen}
      emptyComponent={PlaceOrderEmpty}
    />
  );
};
