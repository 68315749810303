import React, {useEffect, useRef} from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {Checkboxes} from '../../../../Checkboxes/Checkboxes';
import {classes} from './PlaceOrderOpen.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {PlaceOrderDataHook} from '../PlaceOrderStep';
import {ViolationsAndPlaceOrderButton} from '../../../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../../../../types/app.types';
import {
  BottomMobileSummary,
  BottomMobileSummaryDataHooks,
} from '../../../../Summary/BottomMobileSummary/BottomMobileSummary';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../constants';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes as commonClasses} from '../../../../common.st.css';

export const PlaceOrderOpen = () => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const {
    isOneColumnView,
    navigationStore: {isDonationsTheme},
  } = useControllerProps();
  const sectionRootRef = useRef<HTMLDivElement>(null);
  useEffect(
    () => {
      scrollToSectionRoot();
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  const scrollToSectionRoot = () => {
    sectionRootRef?.current?.scrollIntoView({block: 'center'});
  };
  const isSupportNewMobileSummaryBehavior = experiments.enabled(SPECS.SupportNewMobileSummaryBehavior);
  const shouldSupportNewMobileSummaryBehavior = isSupportNewMobileSummaryBehavior && isOneColumnView;

  return (
    <div className={classes.root} data-hook={PlaceOrderDataHook.open} ref={sectionRootRef}>
      <div className={isSupportNewMobileSummaryBehavior ? commonClasses.oneColumnViewPadding : undefined}>
        <div className={classes.subtitle}>
          <Text data-hook={PlaceOrderDataHook.subtitle}>
            {isDonationsTheme
              ? localeKeys.checkout.donations.review_donation.description()
              : localeKeys.checkout.place_order.description()}
          </Text>
        </div>
        <Checkboxes />
      </div>
      {shouldSupportNewMobileSummaryBehavior ? (
        <BottomMobileSummary dataHook={BottomMobileSummaryDataHooks.placeOrderOpen} />
      ) : (
        <ViolationsAndPlaceOrderButton location={ViolationsAndPlaceOrderButtonLocation.placeOrderStep} />
      )}
    </div>
  );
};
