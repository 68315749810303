import {FIELDS_GAP, FORMS_TEMPLATE_IDS, SPECS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {FormProps} from '../ContactForm/ContactForm';
import {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {getCustomerDetailsFormOverrides} from './CustomerDetailsForm.utils';

export type CustomerDetailsFormProps = FormProps;

const CustomerDetailsFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
}: CustomerDetailsFormProps) => {
  const i18n = useICUI18n();
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isDonationsTheme},
    checkoutSettingsStore: {checkoutSettings, isGroo, checkoutComposerEnabled},
    checkoutStore: {isShippingFlow},
    memberStore: {isMember},
  } = useControllerProps();
  const {experiments} = useExperiments();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      formId={FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_ADDITIONAL_INFO}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      overrides={
        getCustomerDetailsFormOverrides({
          localeKeys,
          isDonationsTheme,
          checkoutSettings,
          isGroo,
          excludeGoogleAutoComplete: experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
          shouldFilterByShippingCountries: experiments.enabled(SPECS.FilterCountriesByShippingCountries),
          checkoutComposerEnabled,
          isShippingFlow,
          isMember,
        }) as FormOverrides
      }
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const CustomerDetailsForm = React.memo(CustomerDetailsFormInternal);
