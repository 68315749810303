import {classes} from './Layout.st.css';
import {MainLoader} from './MainLoader/MainLoader';
import {AsideLoader} from './AsideLoader/AsideLoader';
import React, {ReactNode, useEffect, useState} from 'react';
import {checkoutTheme} from '../WithCheckoutThemeOverride/checkoutTheme';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {useControllerProps} from '../Widget/ControllerContext';
import {HeaderLoader} from './HeaderLoader/HeaderLoader';

export interface LayoutProps {
  isLoading: boolean;
  failedToFetch?: boolean;
  Header: () => ReactNode;
  Aside: () => ReactNode;
  Footer: () => ReactNode;
  children: ReactNode;
  dataHook: string;
  dir: string;
}

export enum LayoutDataHooks {
  error = 'LayoutDataHooks.error',
  oneColumnViewSummary = 'LayoutDataHooks.oneColumnViewSummary',
  twoColumnViewSummary = 'LayoutDataHooks.twoColumnViewSummary',
}

export const Layout = ({dataHook, dir, isLoading, failedToFetch, Header, Aside, Footer, children}: LayoutProps) => {
  const {experiments} = useExperiments();
  const {isOneColumnView} = useControllerProps();
  const [loaded, setLoaded] = useState(false);
  const shouldSupportNewMobileSummaryBehavior =
    experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) && isOneColumnView;

  useEffect(() => {
    setLoaded(!isLoading);
  }, [isLoading]);
  return (
    <div data-hook={dataHook} className={classes.root} dir={dir} style={checkoutTheme}>
      <header className={classes.header}>{loaded ? Header() : <HeaderLoader />}</header>
      <div
        className={
          experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? classes.container : classes.containerOld
        }>
        {shouldSupportNewMobileSummaryBehavior && (
          <aside data-hook={LayoutDataHooks.oneColumnViewSummary} className={classes.aside}>
            {loaded ? Aside() : <AsideLoader />}
          </aside>
        )}
        <section
          className={experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? classes.main : classes.mainOld}>
          {loaded ? children : <MainLoader />}
        </section>
        {!shouldSupportNewMobileSummaryBehavior && (
          <aside data-hook={LayoutDataHooks.twoColumnViewSummary} className={classes.aside}>
            {loaded ? Aside() : <AsideLoader />}
          </aside>
        )}
      </div>
      {loaded && Footer()}
      {/* TODO: get UX and error message */}
      {failedToFetch && (
        <div className={classes.error} data-hook={LayoutDataHooks.error}>
          Failed to Load!
        </div>
      )}
    </div>
  );
};
