import {FormValues} from '@wix/form-viewer';
import {VatIDValue} from '@wix/form-fields';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {getCommonVatId} from '../../../../domain/utils/vat.util';
import {VatIdFragment, WixCommonVatType} from '../../../../gql/graphql';

export const getVatFormInitialState = (contact?: ContactModel, nullIfMIssing?: boolean) => {
  if (nullIfMIssing && !contact?.vatId?.id) {
    return {vat_id: null};
  }

  return {
    vat_id: {
      id: contact?.vatId?.id ?? '',
      type: contact?.vatId?.type ?? WixCommonVatType.UNSPECIFIED,
    },
  };
};

export const getVatFromVatFormValues = (vatFormValues: FormValues | null): VatIdFragment | undefined => {
  return vatFormValues ? getCommonVatId(vatFormValues.vat_id as VatIDValue) : /* istanbul ignore next */ undefined;
};
